import React, { useState, useEffect } from "react";
import { MdBrightness6 } from "react-icons/md";

function Header() {
  const [mode, setMode] = useState(localStorage.getItem("mode") || "light");

  useEffect(() => {
    document.body.setAttribute("data-theme", mode);
    localStorage.setItem("mode", mode);
  }, [mode]);

  const toggleMode = () => {
    const newMode = mode === "light" ? "dark" : "light";
    setMode(newMode);
  };

  return (
    <header className="mt-3 mb-2">
      <div className="container px-4 px-md-0">
        <div className="row ">
          <div className="justify-content-end mb-4 mb-lg-0">
            <div className="form-check form-switch float-end d-flex align-items-center">
              <input
                className="form-check-input"
                type="checkbox"
                id="dark-mode-toggle"
                onChange={toggleMode}
                checked={mode === "dark"}
              />
              <label className="form-check-label" htmlFor="dark-mode-toggle">
                <MdBrightness6 className="toggle" />
                <span className="visually-hidden">Light/Dark</span>
              </label>
            </div>
          </div>
          <div className="col-12 mb-4">
            <h1 className="text-center ">
              <span className="visually-hidden">GameStirke</span>
              <a href="/">
                <img
                  src="./images/logo.svg"
                  width="588"
                  height="57"
                  alt="GameStirke"
                  className={`logo img-fluid ${mode}`}
                />
              </a>
            </h1>
            <h2 className="text-center">
            Your destination for video game news<span className="d-none d-sm-inline"> and more</span>.
            </h2>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
